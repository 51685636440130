import React, { memo, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_RECENT_CLIENT_MATTER_LIST } from "../graphql/queries/clientMattersQueries.js";
import MainCard from "../../../components/common/MainCard.jsx";
import CardContent from "@mui/material/CardContent";
import TimeEntryModal from "../TimeEntryGrid/TimeEntryModal";
import jsUtils from "../../../utils/jsUtils.js";

import {
  IconButton,
  List,
  ListItemText,
  ListItemButton,
  LinearProgress,
} from "@mui/material";
import { openSnackbar } from "../../../redux-toolkit/slices/snackbar.js";
import "./RecentClientsMatters.scss";
import CloseIcon from "@mui/icons-material/Close";
import { literals } from "../../../enums/literalCodes";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch,useSelector } from "react-redux";
import ApolloContextProvider from "../../../GraphQL/ApolloContextProvider.js";

const RecentClientsMatter = ({ removeWidgetFromDashboard, widgetId }) => {
  const [selectedTimeEntry, setSelectedTimeEntry] = useState();
  const [clientMatter, setClientMatter] = useState();
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { RecentClientMatterTimeEntry } = useSelector((state) => state.tab);
  const [customerId, setCustomerId] = useState("");
  const [billabeData, setBillableData] = useState({});
  const dispatch = useDispatch();

  const { loading, error, data } = useQuery(GET_RECENT_CLIENT_MATTER_LIST);
  // Update your component's rendering logic based on the loading, error, and data states

  if (error) {
    dispatch(
      openSnackbar({ message: error.message, severity: literals.ERROR })
    );
  }

  const { clientMatters } = data?.getRecentClientMatters || {
    clientMatters: [],
  };

  const handleCloseRecentClientMatter = () => {
    removeWidgetFromDashboard(widgetId);
  };

  const handleListItemClick = (event, id, desc, customerId, enforceBillableFlag, billableType) => {
    const customerIdNumber = customerId ? Number(customerId) : null
    setClientMatter(desc);
    setSelectedTimeEntry(id);
    setSelectedIndex(id);
    setOpen(true);
    setCustomerId(customerIdNumber);
    const billable = jsUtils.getBillableInfo(billableType);
    setBillableData({ enforceBillableFlag, ...billable })
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  const GetSecondaryComponents = () => {
    return (
      <div className="secondaryItems">
        <Tooltip title={literals.REMOVE_WIDGET}>
          <IconButton onClick={handleCloseRecentClientMatter}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <ApolloContextProvider uri='/main/graphql'>
    <MainCard
        className="client-matter-main-card"
        style={{ width: RecentClientMatterTimeEntry ? "50%" : "" }}
        title={literals.RECENT_CLIENT}
        secondary={GetSecondaryComponents()}
      >
        {loading && <LinearProgress />}
        <CardContent className="client-matter-card-content">
          {/* Map over your recent client matter list and render each item as a div */}

          <List component="nav">
            {clientMatters.map(({ description, clientId, matterId, enforceBillableFlag, billableType }, id) => (
              <ListItemButton
                key={id}
                selected={selectedIndex === 2}
                onClick={(event) =>
                  handleListItemClick(event, clientId, description, matterId, enforceBillableFlag, billableType)
                }
              >
                <ListItemText primary={description} />
              </ListItemButton>
            ))}
          </List>
        </CardContent>
      </MainCard>
      <TimeEntryModal
        billableData={billabeData}
        open={open}
        isOpenFromRecentMattersModel={true}
        handleClose={handleModalClose}
        selectedTimeEntry={selectedTimeEntry}
        clientMatter={clientMatter}
        customerId={customerId}
        widgetId={widgetId}
      />
    </ApolloContextProvider>
  );
};
export default memo(RecentClientsMatter);
