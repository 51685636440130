import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from "axios";
import config from '../../config'

const PERMISSION_SYSTEM_ADMIN = 'ApplicationSystemAdministrator';
const PERMISSION_BASIC_ADMIN = 'ApplicationBasicAdministrator';

const RolesPermissionsContext = createContext();




/**
 * Get user roles and permissions from /main GQL service
 * @returns '{ roles, permissions }' object
 */
const makeNetworkRequest = async (sid) => {
    const query = `
      query {
        getCurrentUserRolesAndPermissions {
          roles
          permissions
        }
      }
    `;

    const res = await axios.post(
        '/main/graphql',
        {
            query: query,
        },
        {
            baseURL: config.baseUrl,
            headers: {
                sid: sid,
                'Content-Type': 'application/json',
            },
            withCredentials: false,
        }
    );

    const rolesAndPermissions = res.data.data.getCurrentUserRolesAndPermissions;
    return rolesAndPermissions;
};




export const RolesPermissionsProvider = ({ children }) => {
    const [rolesAndPermissions, setRolesAndPermissions] = useState(null);

    useEffect(() => {
        const callback = async () => {
            const sid = window.localStorage.getItem("sid");
            if (!sid) {
                throw new Error("Unexpected missing sid / user not logged in");
            }
            const data = await makeNetworkRequest(sid);
            setRolesAndPermissions(data);
        };
        callback();
    }, []);

    return (
        <RolesPermissionsContext.Provider value={rolesAndPermissions}>
            {rolesAndPermissions ? children : null}
        </RolesPermissionsContext.Provider>
    );
};

export const useRolesAndPermissions = () => {
    const context = useContext(RolesPermissionsContext);
    if (!context) {
        throw new Error('useRolesPermissions must be used within a RolesPermissionsProvider');
    }
    return context;
};

export const hasRole = (rolesAndPermissions, role) => {
    if (!rolesAndPermissions) {
        return false;
    }

    // TODO This needs to be investigated further, how the Coyote legacy app handles diff admin types
    //      Related to NG-1162 changes
    if (
        rolesAndPermissions.permissions.includes(PERMISSION_SYSTEM_ADMIN) ||
        rolesAndPermissions.permissions.includes(PERMISSION_BASIC_ADMIN)
    ) {
        return true;
    }

    return rolesAndPermissions.roles.includes(role);
};

export const hasPermission = (rolesAndPermissions, permission) => {
    if (!rolesAndPermissions) {
        return false;
    }

    // TODO This needs to be investigated further, how the Coyote legacy app handles diff admin types
    //      Related to NG-1162 changes
    if (
        rolesAndPermissions.permissions.includes(PERMISSION_SYSTEM_ADMIN) ||
        rolesAndPermissions.permissions.includes(PERMISSION_BASIC_ADMIN)
    ) {
        return true;
    }

    return rolesAndPermissions.permissions.includes(permission);
};
