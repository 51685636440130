import { GridRowModes } from "@mui/x-data-grid";
import { literals } from "../../../enums/literalCodes";
import jsUtils from "../../../utils/jsUtils";
import timeEntryService from "../../../services/timeEntryService";
import CustomTimer from "../../../components/common/Timer";
import AutoComplete from "../../../components/common/AutoComplete";
import CssTextField from "../../../components/common/CssTextField";
import { MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UnStyledDatePicker from "../../../components/common/UnStyledDatePicker";
import dayjs from "dayjs";
import AutoCompleteCustom from "../../../components/common/AutoCompleteCustom";

export const getColumnRenderComponent = (
  params,
  column,
  actionHandler,
  val,
  onTimerClicked
) => {
//   let isTimeEntryPosted = params?.row?.status !== literals?.POSTED;
  switch (column.columnRenderType) {
    case "timer":
      return (
          <div className="secondarytimer">
            <CustomTimer
              Component="Grid"
              previousTime={params?.row?.time}
              timerDTO={params?.row?.timerDto}
              status={params?.row?.status}
              id={params?.id}
              isActivitiyId={!jsUtils.isGUID(params?.id)}
              handleAddtoHours={(id,newTime)=>actionHandler(column.field,{id:id,newTime:newTime},params)}
              onTimerClicked={onTimerClicked}
              />
            </div>
        
      );
    default:
      return <div className="MuiDataGrid-cellContent">{val}</div>;
  }
};
export const getColumnEditCellComponent = (
  params,
  column,
  getCalendarIcon,
  val,
  setOptionsChanges,
  errors,
  setSelectedOption,
  OptionDescription,
  entryTypeList,
  actionHandler,
  suggestionsFunction,
  onTimerClicked,
  OptionTimekeeperDescription
) => {

//  let isTimeEntryPosted = params?.row?.status !== literals?.POSTED;
  switch (column.columnRenderEditType) {
    case "timer":
      return (
        <div className="secondarytimer">
          <CustomTimer
            Component="Grid"
            previousTime={params?.row?.time}
            timerDTO={params?.row?.timerDto}
            status={params?.row?.status}
            id={params?.id}
            isActivitiyId={!jsUtils.isGUID(params?.id)}
            handleAddtoHours={(id,newTime)=>actionHandler(column.field,{id:id,newTime:newTime},params)}
            onTimerClicked={onTimerClicked}

          />
          </div>
      );
    case "autocomplete":
      return (
        <AutoComplete
          setOptionsChanges={(selectedOption)=>{actionHandler(column.field,selectedOption,params)}}
          state={params}
          errors={errors}
          selectedOption={params}
          setSelectedOption={()=>{}}
          searchSuggestions={suggestionsFunction}
          getOptionLabel={OptionDescription}
          field={column.field}
          disabled={params?.row?.status !== literals.DRAFT}
        />
      );
    case "autocompletecustom":
      return (
        <AutoCompleteCustom
          setOptionsChanges={(option)=>actionHandler(column.field,option,params)}
          state={params}
          errors={errors}
          searchId={params.row.customerId}
          selectedOption={params}
          setSelectedOption={()=>{}}
          searchSuggestion={suggestionsFunction}
          getOptionLabel={OptionTimekeeperDescription}
          field={column.field}
          disabled={params?.row?.status !== literals.DRAFT}
          // disabled={state?.status == literals.DRAFT ? false: true}
          selectedTimekeeper={params}
        />
      )
    case "dropdown":
      return (
        <CssTextField
          fullWidth
          size="small"
          select
          name={column.field}
          data-testid='dropdown'
          color="primary"
          onChange={(val) =>actionHandler(column.field,val,params)}
          value={val}
        >
          {entryTypeList?.map((entry, index) => (
            <MenuItem key={index} value={entry.description}>
              {entry.description}
            </MenuItem>
          ))}
        </CssTextField>
      );

    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <UnStyledDatePicker
            components={{
              OpenPickerIcon: getCalendarIcon,
            }}
            value={dayjs(params.row[column.field])}
            onChange={(date) => actionHandler(column.field,date,params)}
            name="date"
            InputLabelProps={{ shrink: true }}            
            size="small"
            inputFormat="MM/DD/YYYY"
            color="primary"
            format="MM/DD/YYYY"
            className= "row-editing"
          />
        </LocalizationProvider>
      );
    
    case "input":
        return <input
        style={{marginLeft:'10px'}}
        className={"row-editing"}
        value={params.formattedValue}
        data-testid={'input-field'}
        onChange={(val) => actionHandler(column.field,val,params)}
      />
    default:
      return <div className="MuiDataGrid-cellContent">{val}</div>;
  }
};
